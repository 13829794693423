import { debounce } from '@/utils/date';
export default {
  data: function data() {
    return {
      $_sidebarElm: null,
      $_resizeHandler: null
    };
  },
  mounted: function mounted() {
    this.initListener();
  },
  activated: function activated() {
    if (!this.$_resizeHandler) {
      // avoid duplication init
      this.initListener();
    }

    // when keep-alive chart activated, auto resize
    this.resize();
  },
  beforeDestroy: function beforeDestroy() {
    this.destroyListener();
  },
  deactivated: function deactivated() {
    this.destroyListener();
  },
  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_sidebarResizeHandler: function $_sidebarResizeHandler(e) {
      if (e.propertyName === 'width' || e.propertyName === 'margin-left') {
        this.$_resizeHandler();
      }
    },
    initListener: function initListener() {
      var _this = this;
      this.$_resizeHandler = debounce(function () {
        _this.resize();
      }, 100);
      this.$_sidebarElm = document.getElementsByClassName('layout-cont')[0];
      this.$_sidebarElm && this.$_sidebarElm.addEventListener('transitionend', this.$_sidebarResizeHandler);
    },
    destroyListener: function destroyListener() {
      this.$_resizeHandler = null;
      this.$_sidebarElm && this.$_sidebarElm.removeEventListener('transitionend', this.$_sidebarResizeHandler);
    },
    resize: function resize() {
      var resizeEvent = new Event("resize");
      window.dispatchEvent(resizeEvent);
    }
  }
};